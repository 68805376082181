module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Geschwister Albers","short_name":"Geschwister Albers","start_url":"/","background_color":"#fb8c00","theme_color":"#fb8c00","display":"standalone","icon":"src/images/Niffi.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"428431cce9696aa95b8b94b5ae481df1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"6","matomoUrl":"https://analytics.zorrillamedia.de","siteUrl":"https://www.geschwisteralbers.de","requireConsent":true},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"niffiistderbeste"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
